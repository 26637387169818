// App.jsx
import { HelmetProvider } from "react-helmet-async";
import { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import "./App.scss";
import Sidebar from "./components/Sidebar/Sidebar";
import Footer from "./components/Footer/Footer";
import LiveChat from "./components/LiveChat/LiveChat";
import BackgroundLarge from "./assets/imgs/newBckgrd-3676.webp";
import { useTranslation } from "react-i18next";
import { languages } from "./languages";
import Home from "./Pages/Home";
const AboutUs = lazy(() => import("./Pages/AboutUs"));
const Services = lazy(() => import("./Pages/Services"));
const ClientsSamples = lazy(() => import("./Pages/ClientsExample"));
const Pricing = lazy(() => import("./Pages/Pricing"));
const Analyze = lazy(() => import("./Pages/Analyze"));
const NotFound = lazy(() => import("./Pages/notfound"));

const LanguageWrapper = () => {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (lang) {
      // If language parameter exists but is invalid
      if (!languages.some((l) => l.code === lang)) {
        navigate("/404", { replace: true });
        return;
      }
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n, navigate]);

  return <Outlet />;
};

function App() {
  const { i18n } = useTranslation();
  return (
    <HelmetProvider>
      <div className={i18n.dir() === "rtl" ? "rtl" : ""}>
        <div className="App">
          <img alt="Background" className="background" src={BackgroundLarge} />
          <Router>
            <Sidebar />
            <div className="contentWrapper">
              <AnimatePresence mode="wait">
                <Suspense>
                  <Routes>
                    {/* Explicit 404 route */}
                    <Route path="/404" element={<NotFound />} />

                    {/* Main routes with language */}
                    <Route path="/:lang?" element={<LanguageWrapper />}>
                      <Route index element={<Home />} />
                      <Route path="AboutUs" element={<AboutUs />} />
                      <Route
                        path="ClientsSamples"
                        element={<ClientsSamples />}
                      />
                      <Route path="Services" element={<Services />} />
                      <Route path="Analyze" element={<Analyze />} />
                      <Route path="Pricing" element={<Pricing />} />
                      {/* Catch invalid paths under valid language */}
                      <Route path="*" element={<NotFound />} />
                    </Route>

                    {/* Catch all other invalid paths */}
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              </AnimatePresence>
            </div>
            <Footer />
          </Router>
          <LiveChat />
        </div>
      </div>
    </HelmetProvider>
  );
}

export default App;
