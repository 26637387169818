import MotionHoc from "./MotionHoc";
import "./Home.scss";
import { Suspense, lazy } from "react";
import { Helmet } from "react-helmet-async";
import MainLogo from "/assets/Logcenter.svg";
import { useTranslation } from "react-i18next";
import { languages } from "../languages";
const ContactForm = lazy(() => import("./ContactForm"));

const HomeComponent = () => {
  const { t, i18n } = useTranslation();
  const hour = new Date().getHours();

  const greetings = [
    { start: 5, end: 11, message: t("01", "Good Morning") },
    { start: 12, end: 14, message: t("02", "Good Noon") },
    { start: 15, end: 16, message: t("03", "Good Afternoon") },
    { start: 17, end: 20, message: t("04", "Good Evening") },
    { start: 21, end: 23, message: t("05", "Good Night") },
    { start: 0, end: 4, message: t("06", "Happy late night") },
  ];

  const currentGreeting =
    greetings.find((g) => hour >= g.start && hour <= g.end) || greetings[0];

  const needsExclamation = hour >= 5 && hour <= 19;
  const greeting = `${currentGreeting.message}${needsExclamation ? "!" : ""}`;

  return (
    <div className="MainContainer" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      <Helmet>
        <html lang={i18n.language} />
        {languages.map((lang) => (
          <link
            key={lang.code}
            rel="alternate"
            hrefLang={lang.code}
            href={`https://siliconsquire.com/${lang.code}${window.location.pathname}`}
          />
        ))}
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://siliconsquire.com${window.location.pathname}`}
        />
        <meta property="og:locale" content={i18n.language} />
        <title>{t("meta-title", "Web and SEO Professionals")}</title>
        <meta
          name="description"
          content={t(
            "meta-description",
            "Full-stack digital solutions: Cloud infrastructure, custom development, SEO, and digital marketing. Transform your business with our comprehensive tech expertise.",
          )}
        />
        <link
          rel="canonical"
          href={`https://siliconsquire.com${window.location.pathname}`}
        />
        <link rel="preload" href={MainLogo} as="image" />
      </Helmet>
      {/* Greeting above the logo */}
      <h2 className="greeting-message">{greeting}</h2>

      <div className="home-waviy">
        <img
          src={MainLogo}
          alt="Silicon Squire Logo"
          width={200}
          height={200}
        />
      </div>

      <h1 id="header-title">
        {t(
          "1",
          "Complete Digital Solutions: From Infrastructure to Innovation",
        )}
      </h1>

      <div id="home">
        <div className="messages-container">
          <div className="big-we">
            <span className="highlight">{t("2", "We")}</span>
          </div>
          <div className="messages">
            <p>
              <span className="fade-text first">{t("3", "Build")}</span>
            </p>
            <p>
              <span className="fade-text second">{t("5", "Transform")}</span>
            </p>
            <p>
              <span className="fade-text third">{t("7", "Scale")}</span>
            </p>
            <p>
              <span className="fade-text fourth">{t("9", "Deliver")}</span>
            </p>
          </div>
        </div>
      </div>

      <h2 id="home-h2">
        {t(
          "10",
          "Powering business growth with end-to-end technology solutions. From cloud infrastructure to stunning interfaces, we architect, build, and optimize your complete digital ecosystem. Ready to accelerate your digital journey?",
        )}
      </h2>
      <Suspense>
        <ContactForm />
      </Suspense>
    </div>
  );
};

const Home = MotionHoc(HomeComponent);

export default Home;
