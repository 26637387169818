import { useState, useCallback, useEffect, useRef } from "react";
import io from "socket.io-client";

const SOCKET_URL =
  import.meta.env.VITE_SOCKET_URL || "wss://api.siliconsquire.com";

export const useChat = () => {
  const [state, setState] = useState({
    isOpen: false,
    messages: [],
    inputMessage: "",
    userId: "",
    isConnected: false,
  });

  const socketRef = useRef(null);

  // Initialize userId from localStorage
  useEffect(() => {
    const storedUserId =
      localStorage.getItem("userId") ||
      `user_${Math.random().toString(36).substring(2, 11)}`;

    setState((prev) => ({ ...prev, userId: storedUserId }));
    localStorage.setItem("userId", storedUserId);

    const storedMessages = JSON.parse(localStorage.getItem("messages") || "[]");
    setState((prev) => ({ ...prev, messages: storedMessages }));
  }, []);

  // Save messages to localStorage
  useEffect(() => {
    localStorage.setItem("messages", JSON.stringify(state.messages));
  }, [state.messages]);

  const handleSocketConnection = useCallback(() => {
    if (!socketRef.current) {
      socketRef.current = io(SOCKET_URL, { withCredentials: true });

      socketRef.current.on("connect", () => {
        setState((prev) => ({ ...prev, isConnected: true }));
        socketRef.current.emit("join", state.userId);
      });

      socketRef.current.on("disconnect", () => {
        setState((prev) => ({ ...prev, isConnected: false }));
      });

      socketRef.current.on("message", (message) => {
        setState((prev) => ({
          ...prev,
          messages: [
            ...prev.messages,
            {
              id: Date.now(),
              ...message,
              timestamp: new Date().toISOString(),
            },
          ],
        }));
      });
    }
  }, [state.userId]);

  const toggleChat = useCallback(() => {
    setState((prev) => {
      const nextIsOpen = !prev.isOpen;

      if (nextIsOpen) {
        handleSocketConnection();
      } else if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }

      return { ...prev, isOpen: nextIsOpen };
    });
  }, [handleSocketConnection]);

  const sendMessage = useCallback(
    (e) => {
      e.preventDefault();
      if (!state.inputMessage.trim()) return;

      const newMessage = {
        id: Date.now(),
        text: state.inputMessage,
        sender: "user",
        timestamp: new Date().toISOString(),
      };

      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, newMessage],
        inputMessage: "",
      }));

      if (socketRef.current) {
        socketRef.current.emit("message", {
          userId: state.userId,
          message: state.inputMessage,
        });
      }
    },
    [state.inputMessage, state.userId],
  );

  const setInputMessage = useCallback((message) => {
    setState((prev) => ({ ...prev, inputMessage: message }));
  }, []);

  return {
    ...state,
    toggleChat,
    sendMessage,
    setInputMessage,
  };
};
