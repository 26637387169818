import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FaComments, FaXmark } from "react-icons/fa6";
import { useChat } from "./useChat";
import "./LiveChat.scss";

const ChatMessage = React.memo(({ message, isRTL }) => (
  <div className={`message ${message.sender}`}>
    <div className="message-text">{message.text}</div>
    <small className={`timestamp ${isRTL ? "rtl" : "ltr"}`}>
      {new Date(message.timestamp).toLocaleTimeString()}
    </small>
  </div>
));

const LiveChat = () => {
  const {
    isOpen,
    messages,
    inputMessage,
    isConnected,
    toggleChat,
    sendMessage,
    setInputMessage,
  } = useChat();

  const { t, i18n } = useTranslation();
  const messagesEndRef = useRef(null);
  const isRTL = i18n.language === "ar";

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div
      className={`live-chat ${isOpen ? "open" : ""}`}
      dir={isRTL ? "rtl" : "ltr"}
    >
      <button
        className="chat-toggle"
        aria-label={t(
          isOpen ? "Close live chat" : "Open live chat",
          isOpen ? "Close chat" : "Open chat",
        )}
        onClick={toggleChat}
      >
        {isOpen ? <FaXmark size={24} /> : <FaComments />}
      </button>

      <div
        className={`chat-container ${isOpen ? "open" : "closed"}`}
        role="dialog"
        aria-label={t("Live Chat Dialog", "Live Chat")}
      >
        <div
          className="chat-status"
          style={{ color: isConnected ? "green" : "red" }}
          role="status"
          aria-live="polite"
        >
          {t(
            isConnected ? "162" : "163",
            isConnected ? "Connected" : "Disconnected",
          )}
        </div>

        <div className="chat-messages" role="log">
          {messages.map((msg) => (
            <ChatMessage key={msg.id} message={msg} isRTL={isRTL} />
          ))}
          <div ref={messagesEndRef} />
        </div>

        <form onSubmit={sendMessage} className="chat-input">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder={t("164", "Type a message...")}
            aria-label={t("Message input", "Message input field")}
          />
          <button
            type="submit"
            style={{
              marginLeft: isRTL ? "0" : "8px",
              marginRight: isRTL ? "8px" : "0",
            }}
            aria-label={t("Send message", "Send message")}
          >
            {t("165", "Send")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LiveChat;
