import { useState, useEffect, useRef, useCallback } from "react";
import { NavLink } from "react-router-dom";
import Flag from "react-flagkit";
import "./Sidebar.scss";
import { useTranslation } from "react-i18next";
import logo from "/assets/logoSM.svg";

import {
  FaHouse,
  FaBlog,
  FaGears,
  FaUsers,
  FaChartBar,
  FaMoneyBillWave,
  FaCircleInfo,
} from "react-icons/fa6";

const languages = [
  { code: "en", countryCode: "US", name: "EN" },
  { code: "ar", countryCode: "SA", name: "ع" },
  { code: "ru", countryCode: "RU", name: "RUS" },
];

const Sidebar = () => {
  const { t, i18n } = useTranslation();
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = useCallback(() => {
    setSidebarExpanded((prev) => !prev);
  }, []);

  const handleSidebarHover = useCallback(() => {
    if (window.innerWidth > 768) setSidebarExpanded(true);
  }, []);

  const handleSidebarLeave = useCallback(() => {
    if (window.innerWidth > 768) setSidebarExpanded(false);
  }, []);

  const changeLanguage = useCallback(
    (code) => {
      i18n.changeLanguage(code);
      setSelectedLanguage(code);
      setIsOpen(false);
    },
    [i18n],
  );

  const withMobileSidebarClose = useCallback((originalOnClick) => {
    return (e) => {
      if (window.innerWidth <= 768) setSidebarExpanded(false);
      if (originalOnClick) originalOnClick(e);
    };
  }, []);

  return (
    <div
      className={`container ${
        isSidebarExpanded ? "sidebar-expanded expanded" : ""
      }`}
      onMouseEnter={handleSidebarHover}
      onMouseLeave={handleSidebarLeave}
    >
      <button
        className={`button ${isSidebarExpanded ? "clicked" : ""}`}
        onClick={toggleSidebar}
        aria-label="Toggle Menu Navigation menu"
      />
      <div className="sidebarContainer">
        <div className="logo">
          <img
            className="logosmall"
            width={56}
            height={60}
            src={logo}
            alt="logo"
          />
          <button
            className={`language-selector ${isOpen ? "open" : ""}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="selected-language">
              <Flag
                country={
                  languages.find((lang) => lang.code === selectedLanguage)
                    ?.countryCode || "US"
                }
              />
              <span className={` ${isOpen ? "open" : ""}`} />
            </div>

            {isOpen && (
              <div className="language-dropdown">
                {languages.map((lang) => (
                  <div
                    key={lang.code}
                    className={`language-option ${
                      lang.code === selectedLanguage ? "selected" : ""
                    }`}
                    onClick={() => changeLanguage(lang.code)}
                    role="button"
                    tabIndex="0"
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        changeLanguage(lang.code);
                      }
                    }}
                  >
                    <Flag country={lang.countryCode} />
                  </div>
                ))}
              </div>
            )}
          </button>
        </div>
        <ul
          ref={sidebarRef}
          className={`slickBar ${isSidebarExpanded ? "expanded clicked" : ""}`}
        >
          <li>
            <a
              href="https://blogs.siliconsquire.com"
              className="item"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaBlog size={24} />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("155", "Blog")}
              </span>
            </a>
          </li>
          <li>
            <NavLink to="/" onClick={withMobileSidebarClose()} className="item">
              <FaHouse size={24} />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("156", "Home")}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Services"
              onClick={withMobileSidebarClose()}
              className="item"
            >
              <FaGears size={24} />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("157", "Services")}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/ClientsSamples"
              onClick={withMobileSidebarClose()}
              className="item"
            >
              <FaUsers size={24} />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("158", "Clients")}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Analyze"
              onClick={withMobileSidebarClose()}
              className="item"
            >
              <FaChartBar size={24} />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("159", "Analyze")}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Pricing"
              onClick={withMobileSidebarClose()}
              className="item"
            >
              <FaMoneyBillWave size={24} />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("160", "Pricings")}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/AboutUs"
              onClick={withMobileSidebarClose()}
              className="item"
            >
              <FaCircleInfo size={24} />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("161", "About")}
              </span>
            </NavLink>
          </li>
          <li></li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
