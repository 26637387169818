import { motion } from "framer-motion";
import { disableScroll, enableScroll } from "../components/scrollControl";

const MotionHoc = (Component) => {
  return function HOC() {
    return (
      <motion.div
        initial={{ rotateY: 90, rotate: 10, opacity: 0 }}
        animate={{
          rotateY: 0,
          rotate: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            type: "spring",
            stiffness: 100,
            damping: 10,
          },
        }}
        exit={{
          rotateY: -90,
          rotate: -10,
          opacity: 0,
          transition: { duration: 0.5, type: "spring", ease: "easeInOut" },
        }}
        onAnimationStart={disableScroll}
        onAnimationComplete={enableScroll}
      >
        <Component />
      </motion.div>
    );
  };
};

export default MotionHoc;
